<template>
    <div class="schedule-listings flex-column" :class="{'no-gap': schedules.length}">
      <div class="flex-row ai-c jc-sb">
        <p class="schedule-listings__header default-text">Summary</p>
        <ActionItemComponent
          v-if="schedules.length"
          icon="add-button-icon-yellow.svg"
          iconSize="24"
          label="New event"
          fontColor="#FFA500"
          :isReverse="true"
          @call-to-action="openCreateEventFormModal"/>
      </div>
      <el-scrollbar v-if="schedules.length">
        <div class="schedule-listings__scrollbal-content flex-column">
          <ScheduleSummaryItem v-for="(schedule, index) in schedules" :key="index" :schedule="schedule" @on-view-event="onViewEvent" />
        </div>
      </el-scrollbar>
      <div class="schedule-listings__empty-state-box flex-column ai-c jc-c" v-else>
        <EmptyState
          :hasCustomContent="true" icon="empty-calendar-icon.svg"
          class="schedule-listings__empty-state">
          <template #>
            <Button
              style="box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1); position: relative;"
              :isActive="true"
              transparentFontColor="#4F55F0"
              :isTransparent="true"
              @handle-click="openCreateEventFormModal"
              buttonText="Create new event"/>
          </template>
        </EmptyState>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';
import ScheduleSummaryItem from '@/modules/calendar/components/ScheduleSummaryItem.vue';

export default defineComponent({
  components: {
    ScheduleSummaryItem,
    EmptyState,
    Button,
    ActionItemComponent
  },
  emits: ['open-create-event-modal', 'on-view-event'],
  props: ['schedules'],
  methods: {
    openCreateEventFormModal() {
      this.$emit('open-create-event-modal');
    },
    onViewEvent(schedule) {
      this.$emit('on-view-event', schedule);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.schedule-listings {
  height: 615px;
  &__empty-state {
    left: calc(50% - 100px);
  }
  &__header {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
  }
  &__scrollbal-content {
    padding: .7rem;
    box-sizing: border-box;
  }
  &__empty-state-box {
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px #0C0F4A1A;
    padding: 1rem;
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }
}
.no-gap {
  gap: 0;
}

@include media-sm-max-width() {
  .schedule-listings {
    padding: 0 1rem;
    gap: 1rem;
  }
}

@include media-xs-max-width() {
  .schedule-listings {
    &__empty-state {
      left: calc(50% - 63px);
      top: calc(70% - 158px);
    }
  }
}
</style>
