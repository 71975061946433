<template>
    <div class="schedule-summary-item flex-column jc-sb">
      <p class="schedule-summary-item__text bold default-text">{{ schedule.title ? schedule.title : '-' }}</p>
      <p class="schedule-summary-item__text default-text">{{ schedule?.address }}</p>
      <p class="schedule-summary-item__text default-text">{{ formattedDate }}</p>
      <div class="schedule-summary-item__wrapper flex-row ai-c jc-sb">
        <p class="schedule-summary-item__text yellow default-text"
          v-for="(time, index) in schedule.timeslots" :key="index">
          <span v-if="time.value">{{ time.value }}</span>
          <span v-else>{{ time.startTime }} - {{ time.endTime }}</span>
        </p>
        <ActionItemComponent
          v-if="schedule.type === CALENDAR_EVENT_TYPE_CALENDAR"
          label="View"
          fontColor="#FFA500"
          icon="arrow-right-yellow.svg"
          :isReverse="true"
          fontSize="15px"
          iconSize="14"
          @call-to-action="onViewEvent(schedule)"
        />
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { CALENDAR_EVENT_TYPE_CALENDAR, DISPLAY_DATE_FORMAT } from '@/core/constants';

export default defineComponent({
  components: {
    ActionItemComponent
  },
  props: ['schedule'],
  emit: ['on-view-event'],

  data() {
    return {
      CALENDAR_EVENT_TYPE_CALENDAR
    };
  },

  computed: {
    formattedDate() {
      return this.$filters.formatToDate(this.schedule?.date, DISPLAY_DATE_FORMAT);
    }
  },

  methods: {
    onViewEvent(schedule) {
      this.$emit('on-view-event', schedule);
    }
  }
});
</script>
<style lang="scss" scoped>
  .schedule-summary-item {
    background: #FFF;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px #0C0F4A1A;
    padding: 1.2rem 1.4rem;
    box-sizing: border-box;

    &__wrapper {
        width: 100%;
    }
    &__text {
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: 0.25px;
    }
    &__text.bold {
        font-size: 14px;
        font-weight: 700;
    }
    &__text.yellow {
        color: #FFA500;
    }
  }
</style>
