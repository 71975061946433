<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="view-event flex-column">
        <div class="view-event__container flex-column">
          <div class="view-event__header flex-row ai-c jc-sb">
              <h2 class="view-event__title default-text">Event details</h2>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="view-event__body flex-column jc-sb">
            <section class="view-event__content flex-column" v-if="event && event !== null">
              <template v-for="(item, index) in Object.keys(event)" :key="index">
                <div class="view-event__item flex-column ai-fs" v-if="item !== 'type' && item !== 'id'">
                    <p class="view-event__text bold default-text">
                      {{ item === 'timeslots' ? 'Time' : StringHelper.capitalizeFirstLetter(item) }}
                    </p>
                    <p class="view-event__text default-text" v-if="item !== 'timeslots'">
                      {{ item === 'recipients' ? getEmailList(event[item]) : item === 'date' ? formattedDates(event[item]) : event[item] }}
                    </p>
                    <p class="view-event__text default-text" v-if="item === 'timeslots'">
                      {{ displayTime(event[item]) }}
                    </p>
                </div>
              </template>
            </section>
            <section class="view-event__actions flex-row jc-fe">
              <ActionItemComponent
                label="Edit event"
                fontColor="#FFA500"
                icon="arrow-right-yellow.svg"
                :isReverse="true"
                fontSize="15px"
                iconSize="14"
                @call-to-action="openEditEventForm(event)"
                />
            </section>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { DISPLAY_DATE_FORMAT } from '@/core/constants';
import StringHelper from '@/core/helpers/string.helper';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';

export default defineComponent({
  components: {
    BaseModal,
    ActionItemComponent
  },
  emits: ['on-cancel', 'open-edit-modal'],
  props: ['show', 'event'],
  data() {
    return {
      StringHelper
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['setViewEventDetailsModal']),

    cancel() {
      this.$emit('on-cancel');
    },

    openEditEventForm(event) {
      console.log(event, 'event in view');
      this.$emit('open-edit-modal', event);
      this.setViewEventDetailsModal(false);
    },

    getEmailList(emailArray) {
      if (!emailArray || !emailArray.length) {
        return '';
      }
      const emailList = emailArray.map((item) => item?.email).join(', ');
      return emailList;
    },

    formattedDates(date) {
      return this.$filters.formatToDate(date, DISPLAY_DATE_FORMAT);
    },

    displayTime(timeslots) {
      if (!timeslots || timeslots.length === 0) {
        return '';
      }

      if (this.event !== null && typeof this.event === 'object') {
        const formattedTimes = timeslots.map((slot) => {
          if (slot.value) {
            return slot.value;
          }
          return `${slot.startTime} - ${slot.endTime}`;
        });

        return formattedTimes.filter(Boolean).join(', ');
      }

      return '';
    },
  },
});
</script>
<style lang="scss" scoped>
 .view-event {
    min-width: 500px;
    max-width: 500px;
    position: relative;

    &__container {
      gap: 2rem;
    }

    &__content {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
        height: 100%;
    }
    &__item {
        gap: .4rem;
    }
    &__text {
      font-size: 16px;
      color: rgba(12, 15, 74, 0.50);
    }
    &__text.bold {
      font-weight: 700;
      color: #0C0F4A;
    }
 }
</style>
