<template>
  <DefaultGridLayoutSlot v-if="hasMainLayoutInitialized">
    <CalendarComponent :attributes="attributes" @reload-schedule="reloadSchedules" @open-edit-modal="onOpenEventFormModal($event)" @set-active-object="setActiveObject" v-if="!initializing"/>
    <!-- <CalendarSummary :data="activeObject" v-if="!initializing"/> -->
    <ScheduleSummaryListings :schedules="summaryEvents" v-if="!initializing"
      @open-create-event-modal="onOpenEventFormModal"
      @on-view-event="onOpenViewEventDetailsModal"/>
    <div class="loader-container flex-row ai-c jc-c" v-if="initializing">
      <Loader v-show="initializing"/>
    </div>

    <!-- MODALS -->
    <CreateEventFormModal :viewSchedule="selectedSchedule" :show="showCreateEventFormModal" @on-cancel="onCloseEventFormModal"/>
    <ViewEventModal :event="selectedSchedule" :show="viewEventDetailsModal" @open-edit-modal="onOpenEventFormModal($event)" @on-cancel="onCloseViewEventDetailsModal"/>
  </DefaultGridLayoutSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import CalendarComponent from '@/core/components/common/calendar/CalendarComponent.vue';
import CreateEventFormModal from '@/core/components/common/modals/CreateEventFormModal.vue';
import ViewEventModal from '@/core/components/common/modals/ViewEventModal.vue';
import DefaultGridLayoutSlot from '@/core/components/slots/DefaultGridLayoutSlot.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
// import CalendarSummary from '@/modules/calendar/components/CalendarSummary.vue';
import ScheduleSummaryListings from '@/modules/calendar/components/ScheduleSummaryListings.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    // CalendarSummary,
    CalendarComponent,
    DefaultGridLayoutSlot,
    Loader,
    ScheduleSummaryListings,
    CreateEventFormModal,
    ViewEventModal
  },
  data() {
    // const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      initializing: false,
      activeObject: null,
      attributes: [], // pinned data events per day in the calendar

      summaryEvents: [], // detailed view items

      showCreateEventFormModal: false,

      selectedSchedule: null
    };
  },

  async created() {
    await this.loadSchedules();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters(USER_CALENDAR_STORE, ['viewEventDetailsModal']),
  },

  methods: {
    ...mapActions(OPPORTUNITY_STORE, [
      'getUserCourses'
    ]),
    ...mapActions(USER_CALENDAR_STORE, ['getSchedules', 'setViewEventDetailsModal']),

    setLoaderFalse() {
      setTimeout(() => {
        this.initializing = false;
      }, 300);
    },

    onOpenViewEventDetailsModal(schedule) {
      this.selectedSchedule = schedule;
      this.setViewEventDetailsModal(true);
    },
    onCloseViewEventDetailsModal() {
      this.setViewEventDetailsModal(false);
      this.selectedSchedule = null;
    },

    onOpenEventFormModal(schedule = null) {
      if (schedule) {
        // set for view
        this.selectedSchedule = schedule;
      }
      this.showCreateEventFormModal = true;
    },

    onCloseEventFormModal(success = false) {
      this.showCreateEventFormModal = false;

      this.selectedSchedule = null;

      if (success) {
        this.summaryEvents = [];
        this.attributes = [];
        // we need to load and re-initialize to get the new list
        this.loadSchedules();
      }
    },

    reloadSchedules() {
      this.selectedSchedule = null;
      this.summaryEvents = [];
      this.attributes = [];
      // we need to load and re-initialize to get the new list
      this.loadSchedules();
    },

    async loadSchedules() {
      this.initializing = true;
      const { userId } = this.authenticatedUser;
      const params = {
        userId,
        filter: {}
      };

      await this.getUserCourses(params).then((response) => {
        const { data: userCourses } = response;

        userCourses.map((userCourse) => {
          const { id, course, userCourseSchedules } = userCourse;

          if (userCourseSchedules.length) {
            const [userMainSchedule] = userCourseSchedules;

            const attribute = {
              id,
              customData: {
                id,
                title: course.name,
                class: 'bg-red-600 text-white',
                startDate: userMainSchedule?.courseSchedule?.startDate
              },
              dates: userMainSchedule?.courseSchedule?.startDate
            };

            this.attributes.push(attribute);
          }
          return {
            ...userCourse
          };
        });
      })
        .catch(() => {})
        .finally(() => {});

      // add the main calendar schedules
      const options = { all: '1' };
      const calendarParams = {
        userId,
        options
      };

      await this.getSchedules(calendarParams).then((response) => {
        if (response?.data && response?.data.length) {
          response.data.forEach((row) => {
            const {
              id, schedule, title, meta, address, user, type, note, recipients
            } = row;

            // event has 1:1 record
            const [eventSchedule] = schedule;

            const attribute = {
              id, // possible clash index?
              customData: {
                id,
                title: title || meta?.projectName,
                class: 'bg-red-600 text-white',
                startDate: eventSchedule.date,
                address: address || user?.postcode,
                date: eventSchedule.date,
                timeslots: eventSchedule.timeslots,
                note,
                recipients,
                type
              },
              dates: eventSchedule.date
            };

            this.attributes.push(attribute);

            this.summaryEvents.push({
              id,
              type,
              title: title || meta?.projectName,
              address: address || user?.postcode,
              date: eventSchedule.date,
              timeslots: eventSchedule.timeslots,
              note,
              recipients
            });
          });
        }
      }).catch(() => {
        this.$notify.error({
          title: 'Error in retrieving schedules',
          message: GENERIC_ERROR_MESSAGE,
        });
      })
        .finally(() => {
          this.setLoaderFalse();
        });
    },

    setActiveObject(object) {
      this.activeObject = object.customData;
    }
  },
});
</script>
<style lang="scss" scoped>
.loader-container {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 5%;
  height: 100%;
}
</style>
